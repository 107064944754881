import './App.css';
import ApiHealthCheck from './ApiHealthCheck';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>API Status Page</h1>
        <ApiHealthCheck url="https://egg-counter-api.app.vtxhub.com/api/chicks/get" name="Egg Counter API"  />
      </header>
    </div>
  );
}

export default App;
