import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';

const ApiHealthCheck = ({ url, name }) => {
  const [status, updateStatus] = useState(false);

  useEffect(() => {
    
    fetch(`${url}`)
    .then((response) => {
      console.log(response.status)
      if(response.status == 200){
        updateStatus(true)
      }
    })
  }, []);

  return <div> {status ? <AiOutlineCheckCircle /> : <BiError />} - {name} </div>;
};

export default ApiHealthCheck;
